import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "短信模板",
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    onClose: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$emit('close')))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("取 消")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.saveSMS()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("确 定")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formSmsTemplate,
        "label-width": _ctx.formLabelWidth
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "插入标签" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.makeActive('{用户名}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("用户名")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.makeActive('{医院名称}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("医院名称")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.makeActive('{科室名称}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("科室名称")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.makeActive('{专家名称}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("专家名称")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.makeActive('{就诊时间}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("就诊时间")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.makeActive('{预约时间}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("预约时间")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.makeActive('{时间段}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("时间段")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.makeActive('{时间段之前}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("时间段之前")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.makeActive('{号续}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("号续")
                ]),
                _: 1
              }),
              _createVNode(_component_el_tag, {
                type: "info",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.makeActive('{订单号}')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("订单号")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "通用模板" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                id: "TemplateContent0",
                onFocus: _cache[10] || (_cache[10] = ($event: any) => (_ctx.focussms('TemplateContent0'))),
                type: "textarea",
                autosize: "",
                modelValue: _ctx.formSmsTemplate.TemplateContent0,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formSmsTemplate.TemplateContent0) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "电信模板" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                id: "TemplateContent1",
                onFocus: _cache[12] || (_cache[12] = ($event: any) => (_ctx.focussms('TemplateContent1'))),
                autosize: "",
                modelValue: _ctx.formSmsTemplate.TemplateContent1,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formSmsTemplate.TemplateContent1) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "移动模板" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                id: "TemplateContent2",
                onFocus: _cache[14] || (_cache[14] = ($event: any) => (_ctx.focussms('TemplateContent2'))),
                autosize: "",
                modelValue: _ctx.formSmsTemplate.TemplateContent2,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formSmsTemplate.TemplateContent2) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "联通模板" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                id: "TemplateContent3",
                onFocus: _cache[16] || (_cache[16] = ($event: any) => (_ctx.focussms('TemplateContent3'))),
                autosize: "",
                modelValue: _ctx.formSmsTemplate.TemplateContent3,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formSmsTemplate.TemplateContent3) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "label-width"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}