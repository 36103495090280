
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  computed,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import store from "@/store";
import HospitalSMS from "./HospitalSMS.vue";
import HospitalRules from "./HospitalRules.vue";
import RegisterTime from "./RegisterTime.vue";
import RegisterTip from "./RegisterTip.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigHospital",
  components: { HospitalRules, HospitalSMS, RegisterTime, RegisterTip },
  setup() {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 99],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-orderid",
      },
      rulesDialogVisible: false,
      smsDialogVisible: false,
      timeDialogVisible: false,
      tipDialogVisible: false,
      timeStatus: [],
    });

    const searchChange = (params: any, done: any) => {
      console.log(params);
      if (!params["HospitalName"] && !params["cityid"]) {
        ElMessage.warning("请选择地市或输入医院名称");
        done();
        return;
      }
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      if (!data.query.where["HospitalName"] && !data.query.where["cityid"]) {
        return;
      }
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetHospitalList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          data.data.data.forEach((element: any) => {
            element.cityid = element.cityid + "";
          });
          getTimeStatusList();
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getTimeStatusList = () => {
      var ids: number[] = [];
      data.data.data.forEach((element: any) => {
        if (!element.Docking) ids.push(element.HospitalID);
      });
      if (ids.length == 0) return;
      var action = {
        timetype: 1,
        ids: ids,
      };
      Api.Config.GetTimeStatusList(action).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          data.timeStatus = res.Data;
        } else {
          data.data = [];
        }
      });
    };

    const haveTime = computed(() => (id: number) => {
      var status = data.timeStatus.find((item: any) => item.id == id);
      if (status) {
        return data.timeStatus.find((item: any) => item.id == id).have
          ? "primary"
          : "warning";
      } else {
        return "warning";
      }
    });

    const getOption = () => {
      Api.Config.GetHospitalOption().then((res: any) => {
        console.log(res);
        const localHospital: string = store.getters.localHospital;
        if (localHospital) {
          const jsonHospital = JSON.parse(localHospital);
          jsonHospital.forEach((province: any) => {
            province.children.forEach((city: any) => {
              city.value += "";
              delete city.children;
            });
          });
          res.column.filter((c: any) => {
            if (c.prop == "cityid") c.dicData = jsonHospital;
          });
        }
        data.option = res;
        data.init = true;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      console.log(form);
      if (form.photo.length == 0) {
        form.photo = "";
      } else {
        form.photo = form.photo[0];
      }
      Api.Config.AddHospital(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      console.log(form);
      Api.Config.EditHospital(form.HospitalID, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const HospitalRemove = (row: any) => {
      Api.Config.EditHospital(row.HospitalID, row).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const sortableChange = (oldindex: number, newindex: number) => {
      if (!data.query.where["cityid"] || data.query.where["HospitalName"]) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请选择一个地市，清空医院名称查询条件，查询后进行排序",
        });
        getList();
        return;
      }
      if (oldindex == newindex) return;
      const order = {
        key: data.data.data[newindex].HospitalID,
        steps: oldindex - newindex,
      };
      Api.Config.OrderHospital(order).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const HospitalRulesRef = ref<InstanceType<typeof HospitalRules>>();
    const SetRules = (row: any) => {
      data.rulesDialogVisible = true;
      HospitalRulesRef.value?.getRules(row.HospitalID);
    };
    const HospitalSMSRef = ref<InstanceType<typeof HospitalSMS>>();
    const SetSMS = (row: any) => {
      data.smsDialogVisible = true;
      HospitalSMSRef.value?.getSmsTemplate(row.HospitalID);
    };

    const RegisterTimeRef = ref<InstanceType<typeof RegisterTime>>();
    const SetTime = (row: any) => {
      data.timeDialogVisible = true;
      RegisterTimeRef.value?.searchChange(1, row.HospitalID, row.HospitalName);
    };

    const RegisterTipRef = ref<InstanceType<typeof RegisterTip>>();
    const SetTip = (row: any) => {
      console.log(row);

      data.tipDialogVisible = true;
      RegisterTipRef.value?.searchChange(
        3,
        row.HospitalID,
        0,
        row.HospitalName
      );
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      HospitalRemove,
      sortableChange,
      SetRules,
      SetSMS,
      SetTime,
      HospitalRulesRef,
      HospitalSMSRef,
      RegisterTimeRef,
      SetTip,
      RegisterTipRef,
      haveTime,
    };
  },
});
