
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import type { FormInstance } from "element-plus";

export default defineComponent({
  name: "ConfigHospitalSMS",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      smsinput: "TemplateContent0",
      HospitalID: "",
      formSmsTemplate: {
        TemplateContent0: "",
        TemplateContent1: "",
        TemplateContent2: "",
        TemplateContent3: "",
      },
      formLabelWidth: "140px",
      formRulesRef: ref<FormInstance>(),
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const saveSMS = () => {
      Api.Config.EditSMS(data.HospitalID, data.formSmsTemplate).then(
        (res: any) => {
          if (res.Code == 0) {
            ElMessage.success("保存成功");
            emit("close");
          } else {
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        }
      );
    };

    const focussms = (input: string) => {
      data.smsinput = input;
    };

    const makeActive = (item: string) => {
      var elInput: any = document.getElementById(data.smsinput); // 根据id选择器选中对象
      var startPos = elInput.selectionStart; // input 第0个字符到选中的字符
      var endPos = elInput.selectionEnd; // 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return;
      var txt = elInput.value;
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + item + txt.substring(endPos);
      elInput.value = result; // 赋值给input的value
      // 重新定义光标位置
      elInput.focus();
      elInput.selectionStart = startPos + item.length;
      elInput.selectionEnd = startPos + item.length;
      data.formSmsTemplate[data.smsinput] = result; // 赋值给表单中的的字段
      console.info(data.formSmsTemplate);
    };

    const getSmsTemplate = (HospitalID: string) => {
      console.log(HospitalID);

      Api.Config.GetHospitalSMS(HospitalID).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          data.HospitalID = HospitalID;
          data.formSmsTemplate = res.Data;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          emit("close");
        }
      });
    };

    return {
      ...toRefs(data),
      ...toRefs(props),
      dialogVisible,
      getSmsTemplate,
      saveSMS,
      makeActive,
      focussms,
    };
  },
});
